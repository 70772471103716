import { useEffect, useState } from "react"
import { Container, Box, Typography, Grid, Card, Button, Radio, RadioGroup, FormControlLabel } from "@mui/material";
import { Helmet } from "react-helmet";
import { styled } from 'styled-components';
import { OutlinedButton } from './../../components/OutlinedButton'
import { global } from "./../../service/GlobalController"
import { get as _get } from "lodash";


// assets
import LogoNeon from "./../../assets/img/Koindx_logo_3D.png";
import KoindxToken from "./../../assets/img/koindx_token.png";
import InfoBol from "./../../assets/img/info_bol.png";
import InfoCros from "./../../assets/img/info_cros.png";
import InfoTokens from "./../../assets/img/tokens_circle.png";

// icons
import IconArrow from "./../../assets/img/arrow.svg";
import InconLight from "./../../assets/img/light.svg";
import InconSpeaker from "./../../assets/img/speaker.svg";

// partners
import RRS from "./../../assets/img/partner/Royal_Reptile_Studios_white_small.png"
import KG from "./../../assets/img/partner/koinos_group_white.svg"
import PK from "./../../assets/img/partner/planet_koinos_white.svg"
import KoinCity from "./../../assets/img/partner/Koincity_text_white.png"

const PartnerImage = styled.img`
    filter: opacity(50%);
    &:hover {  filter: opacity(100%)
    } 
`

const PartnerTile = (props) => {
  const { image, alt, link = false, title, imageH = 200 } = props
  return (
    <Box sx={{ display: "block" }}>
      {link ?
        <a target={"_blank"} href={link} title={title} rel="noreferrer">
          <PartnerImage src={image} alt={alt} height={imageH} style={{ objectFit: "contain" }} />
        </a> :
        <PartnerImage src={image} alt={alt} height={imageH} style={{ objectFit: "contain" }} />
      }
    </Box>
  )
}



const IndexPage = () => {


  const [tvl, setTvl] = useState("-")
  const [volumen, setVolumen] = useState("-")

  useEffect(() => {
      const load = async () => {
          try {
              let result = await global.getData();
              if(!result.errors && !result.error) {
                  let _tvl = _get(result, "totalLiquidityUSD", "-");
                  let _volume = _get(result, "totalVolumeUSD", "-");
                  setTvl(_tvl != "-" ? Math.floor(_tvl) : _tvl)
                  setVolumen(_volume != "-" ? Math.floor(_volume) : _volume)
              }
              setTimeout(() => load(), 10000)
          } catch (error) {
              console.log(error)
          }
      }
      load()
  }, [])

  return (
    <Box>
      <Helmet>
        <meta charSet="utf-8" />
        <title>KoinDX</title>
        <link rel="canonical" href="https://koindx.com" />
        <meta name="description" content="KoinDX - The leading crypto trading protocol on Koinos blockchain." />
      </Helmet>

      <Container>

        {/* Launch */}
        <Grid container sx={{ marginTop: 15, flexDirection: { xs: "column-reverse", sm: "row" } }}>
          <Grid item xs={12} sm={6} sx={{ display: "flex", justifyContent: "start", alignItems: "center" }}>
            <Box>
              <Typography sx={{ display: "block", fontSize: { xs: "36px", sm: "45px" } }} variant="titles2">DEX. EVOLVED.</Typography>
              <Typography sx={{ display: "block", fontSize: { xs: "36px", sm: "45px" } }} variant="titles1">DECENTRALIZED.</Typography>
              <Typography sx={{ display: "block", fontSize: { xs: "36px", sm: "45px" } }} variant="titles2">GASLESS. DEFI.</Typography>
              <Typography sx={{ display: "block", fontSize: { xs: "18px", sm: "24px" } }} variant="subtitle3">KoinDX brings trading closer to you. Fast, Trustful and Gasless. The ultimate DEX powered by the Koinos Blockchain.</Typography>
              <Button sx={{ marginTop: "10px" }} variant="contained" size="large" href="https://app.koindx.com">LAUNCH APP</Button>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} sx={{ display: "flex", justifyContent: { xs: "center", sm: "end" }, alignItems: "center" }}>
            <Box>
              <img src={LogoNeon} />
            </Box>
          </Grid>
        </Grid>

        {/* Features */}
        <Grid container spacing={6} sx={{ marginTop: 15 }}>
          <Grid item xs={12} sm={4} sx={{ display: "flex", justifyContent: { xs: "center", sm: "start"  }, alignItems: "center" }}>
            <Card variant="outlined" sx={{ border: "1px #a3a3a35c solid", width: 300, height: 260, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
              <Box sx={{ margin: 5 }}>
                <img src={IconArrow} style={{ width: 40, display: "block", margin: "auto" }} />
                <Typography sx={{ marginY: 2, textAlign: "center" }} variant="subtitle2">Feel Safe</Typography>
                <Typography sx={{ marginY: 2, textAlign: "center", display: "flex" }} variant="subtitle3">KoinDX makes sure that all your trades on our platform are secure, safe and trustfull.</Typography>
              </Box>
            </Card>
          </Grid>
          <Grid item xs={12} sm={4} sx={{ display: "flex", justifyContent: { xs: "center", sm: "center" }, alignItems: "center" }}>
            <Card variant="outlined" sx={{ border: "1px #a3a3a35c solid", width: 300, height: 260, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
              <Box sx={{ margin: 5 }}>
                <img src={InconLight} style={{ width: 40, display: "block", margin: "auto" }} />
                <Typography sx={{ marginY: 2, textAlign: "center" }} variant="subtitle2">More Power</Typography>
                <Typography sx={{ marginY: 2, textAlign: "center", display: "flex" }} variant="subtitle3">Get your hands on more DEX, more DEFI and more Decentralized Power!</Typography>
              </Box>
            </Card>
          </Grid>
          <Grid item xs={12} sm={4} sx={{ display: "flex", justifyContent: { xs: "center", sm: "end" }, alignItems: "center" }}>
            <Card variant="outlined" sx={{ border: "1px #a3a3a35c solid", width: 300, height: 260, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
              <Box sx={{ margin: 5 }}>
                <img src={InconSpeaker} style={{ width: 40, display: "block", margin: "auto" }} />
                <Typography sx={{ marginY: 2, textAlign: "center" }} variant="subtitle2">$KOINDX</Typography>
                <Typography sx={{ marginY: 2, textAlign: "center", display: "flex" }} variant="subtitle3">Secure, safe and valuable; our very own token. Unlock all that KoinDX has to offer by holding $KOINDX</Typography>
              </Box>
            </Card>
          </Grid>
        </Grid>

        {/* TOKEN */}
        <Grid container spacing={5} sx={{ marginTop: 15 }}>
          <Grid item xs={12} sm={6} sx={{ display: "flex", justifyContent: "start", alignItems: "center" }}>
            <Box>
              <Box sx={{ display: "flex" }}>
                <Typography sx={{ display: "block", fontSize: { xs: "36px", sm: "45px" } }} variant="titles2">THE</Typography>
                <Typography sx={{ display: "block", fontSize: { xs: "36px", sm: "45px" }, marginX: 1 }} variant="titles1">$KOINDX</Typography>
                <Typography sx={{ display: "block", fontSize: { xs: "36px", sm: "45px" } }} variant="titles2">TOKEN</Typography>
              </Box>
              <Typography sx={{ display: "block", fontSize: { xs: "18px", sm: "24px" } }} variant="subtitle3">Passively earn a share of revenue and get discounted trades.</Typography>

              <Box sx={{ display: "flex", marginTop: 2 }}>
                <Button sx={{ marginRight: "10px" }} variant="contained" size="large" href="https://medium.com/@koindx/koindx-token-announcement-26aa75d7dd6c">LEARN MORE</Button>
                <Button sx={{ marginRight: "10px" }} variant="second" size="large" href="https://sale.koindx.com">BUY $KOINDX</Button>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} sx={{ display: "flex", justifyContent: { xs: "center", sm: "end"  }, alignItems: "center" }}>
            <Box
              component="img"
              sx={{ height: { xs: 300, sm: 400 }, }}
              src={KoindxToken}
            />
          </Grid>
        </Grid>

        {/* Volume and TVL */}
        <Grid container spacing={5} sx={{ marginTop: 15 }}>
          <Grid item xs={12}>
            <Card variant="outlined" sx={{ border: "1px #a3a3a35c solid", width: "100%",  display: "flex", justifyContent: "center", alignItems: "center" }}>
              <Box sx={{ margin: 5, textAlign: "center" }}>
                <Typography sx={{ display: "block", fontSize: { xs: "18px", sm: "24px" } }} variant="subtitle3">TOTAL TRADING VOLUME</Typography>
                <Typography sx={{ display: "block", fontSize: { xs: "36px", sm: "45px" } }} variant="titles2">$ { volumen != "-" ? volumen.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",") : "-" }</Typography>
              </Box>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card variant="outlined" sx={{ border: "1px #a3a3a35c solid", width: "100%",  display: "flex", justifyContent: "center", alignItems: "center" }}>
              <Box sx={{ margin: 5, textAlign: "center" }}>
                <Typography sx={{ display: "block", fontSize: { xs: "18px", sm: "24px" } }} variant="subtitle3">TOTAL VALUE LOCKED</Typography>
                <Typography sx={{ display: "block", fontSize: { xs: "36px", sm: "45px" } }} variant="titles2">$ { tvl != "-" ? tvl.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",") : "-" }</Typography>
              </Box>
            </Card> 
          </Grid>
        </Grid>


        {/* Infografi 1 */}
        <Grid container spacing={5} sx={{ marginTop: 15, flexDirection: { xs: "column-reverse", sm: "row" } }}>
          <Grid item xs={12} sm={6} sx={{ display: "flex", justifyContent: { xs: "center", sm: "start" }, alignItems: "center" }}>
            <Box
              component="img"
              sx={{ height: { xs: 300, sm: 400 }, }}
              src={InfoTokens}
            />
          </Grid>
          <Grid item xs={12} sm={6} sx={{ display: "flex", justifyContent: "end", alignItems: "center" }}>
            <Box>
              <Typography sx={{ display: "block", fontSize: { xs: "36px", sm: "45px" } }} variant="titles2">ALL YOUR</Typography>
              <Box sx={{ display: "flex" }}>
                <Typography sx={{ display: "block", fontSize: { xs: "36px", sm: "45px" } }} variant="titles1">FAVORITE</Typography>
                <Typography sx={{ display: "block", fontSize: { xs: "36px", sm: "45px" }, marginX: 1 }} variant="titles2">TOKENS</Typography>
              </Box>
              <Typography sx={{ display: "block", fontSize: { xs: "18px", sm: "24px" } }} variant="subtitle3">Stables, Doubloons, Bitkoin and many more! Get your hands on your favorite tokens.</Typography>
            </Box>
          </Grid>
        </Grid>

        {/* Infografi 2 */}
        <Grid container spacing={5} sx={{ marginTop: 15 }}>
          <Grid item xs={12} sm={6} sx={{ display: "flex", justifyContent: "start", alignItems: "center" }}>
            <Box>
              <Typography sx={{ display: "block", fontSize: { xs: "36px", sm: "45px" } }} variant="titles2">GET YOUR</Typography>
              <Box sx={{ display: "flex" }}>
                <Typography sx={{ display: "block", fontSize: { xs: "36px", sm: "45px" } }} variant="titles1">LIQUIDITY</Typography>
                <Typography sx={{ display: "block", fontSize: { xs: "36px", sm: "45px" }, marginX: 1 }} variant="titles2">ON</Typography>
              </Box>
              <Typography sx={{ display: "block", fontSize: { xs: "18px", sm: "24px" } }} variant="subtitle3">Ad or remove liquidity in just a few clicks. Get control over your assets!</Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} sx={{ display: "flex", justifyContent: { xs: "center", sm: "end" }, alignItems: "center" }}>
           <Box
              component="img"
              sx={{ height: { xs: 300, sm: 400 }, }}
              src={InfoBol}
            />
          </Grid>
        </Grid>


        {/* Infografi 3 */}
        <Grid container spacing={5} sx={{ marginTop: 15, flexDirection: { xs: "column-reverse", sm: "row" } }}>
          <Grid item xs={12} sm={6} sx={{ display: "flex", justifyContent: { xs: "center", sm: "start" }, alignItems: "center" }}>
            <Box
              component="img"
              sx={{ height: { xs: 300, sm: 400 }, }}
              src={InfoCros}
            />
          </Grid>
          <Grid item xs={12} sm={6} sx={{ display: "flex", justifyContent: "end", alignItems: "center" }}>
            <Box>
              <Box sx={{ display: "flex" }}>
                <Typography sx={{ display: "block", fontSize: { xs: "36px", sm: "45px" } }} variant="titles2">LOW FEES +</Typography>
                <Typography sx={{ display: "block", fontSize: { xs: "36px", sm: "45px" }, marginX: 1 }} variant="titles1">HIGH</Typography>
              </Box>
              <Typography sx={{ display: "block", fontSize: { xs: "36px", sm: "45px" } }} variant="titles1">SPEED TRADING</Typography>
              <Typography sx={{ display: "block", fontSize: { xs: "18px", sm: "24px" } }} variant="subtitle3">Move around KoinDX easy and  fast combined with  the lowest fees possible.</Typography>
            </Box>
          </Grid>
        </Grid>

        {/* HIGHLIGHTS */}
        <Box sx={{ textAlign: "center", marginTop: 25, display: "flex", justifyContent: "center", textAling: "center" }}>
          <Typography sx={{ display: "block", fontSize: { xs: "36px", sm: "45px" }, marginX: 1 }} variant="titles2">KOINDX</Typography>
          <Typography sx={{ display: "block", fontSize: { xs: "36px", sm: "45px" }, marginX: 1 }} variant="titles1">HIGHLIGHTS</Typography>
        </Box>
        <Grid container sx={{ marginTop: 5, }}>
          <Grid item xs={12} sm={4} sx={{ margin: { xs: 5, sm: 0 }, display: "flex", justifyContent: { xs: "center", sm: "start" }, alignItems: "center" }}>
            <Card variant="outlined" sx={{ border: "1px #a3a3a35c solid", width: 300, height: 260, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
              <Typography sx={{ marginY: 2, textAlign: "center" }} variant="subtitle1">Discounted trading</Typography>
              <Typography sx={{ marginY: 2, textAlign: "center" }} variant="subtitle3">Stake $KOINDX and recieve discounts.</Typography>
              <Button variant="outlined" sx={{ marginY: 2 }}>SOON</Button>
            </Card>
          </Grid>
          <Grid item xs={12} sm={4} sx={{ margin: { xs: 5, sm: 0 }, display: "flex", justifyContent: { xs: "center", sm: "center" }, alignItems: "center" }}>
            <Card variant="outlined" sx={{ border: "1px #a3a3a35c solid", width: 300, height: 260, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
              <Typography sx={{ marginY: 2, textAlign: "center" }} variant="subtitle1">Yield Farming</Typography>
              <Typography sx={{ marginY: 2, textAlign: "center" }} variant="subtitle3">Provide trading liquidity and recieve returns.</Typography>
              <Button variant="outlined" sx={{ marginY: 2 }} href="https://docs.koindx.com/protocol/yieldfarming">LEARN MORE</Button>
            </Card>
          </Grid>
          <Grid item xs={12} sm={4} sx={{ margin: { xs: 5, sm: 0 }, display: "flex", justifyContent: { xs: "center", sm: "end" }, alignItems: "center" }}>
            <Card variant="outlined" sx={{ border: "1px #a3a3a35c solid", width: 300, height: 260, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
              <Typography sx={{ marginY: 2, textAlign: "center" }} variant="subtitle1">Governance</Typography>
              <Typography sx={{ marginY: 2, textAlign: "center" }} variant="subtitle3">Use your $KOINDX to participate in governance votings.</Typography>
              <Button variant="outlined" sx={{ marginY: 2 }}>SOON</Button>
            </Card>
          </Grid>          
        </Grid>

        {/* Roadmap */}
        <Grid container sx={{ marginTop: 25 }}>
          <Grid item xs={12} sm={6} sx={{ display: { xs: "flex", sm: "block" }, justifyContent: { xs: "center", sm: "flex-start" } }}>
            <Typography sx={{ display: "block", fontSize: { xs: "36px", sm: "45px" }, marginX: { xs: "5px" } }} variant="titles2">KOINDX</Typography>
            <Typography sx={{ display: "block", fontSize: { xs: "36px", sm: "45px" }, marginX: { xs: "5px" } }} variant="titles1">ROADMAP</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Card variant="outlined" sx={{ border: "1px #a3a3a35c solid", padding: 5 }}>
              <RadioGroup defaultValue="3">
                <FormControlLabel value="1" disabled control={<Radio />} label={<Typography variant="h5">Launch Farms Genesis</Typography>} />
                <FormControlLabel value="2" disabled control={<Radio />} label={<Typography variant="h5">Announce IDO details</Typography>} />
                <FormControlLabel value="3" control={<Radio />}          label={<Typography variant="h5">Update Landing Page</Typography>} />
                <FormControlLabel value="4" disabled control={<Radio />} label={<Typography variant="h5">IDO and TGE</Typography>} />
                <FormControlLabel value="5" disabled control={<Radio />} label={<Typography variant="h5">Governance</Typography>} />
                <FormControlLabel value="6" disabled control={<Radio />} label={<Typography variant="h5">Launch Analytics</Typography>} />
                <FormControlLabel value="7" disabled control={<Radio />} label={<Typography variant="h5">Aggregator Integration</Typography>} />
              </RadioGroup>
            </Card>
          </Grid>
        </Grid>

        {/* Partners */}
        <Box sx={{ textAlign: "center", marginTop: 25 }}>
          <Typography sx={{ display: "block", fontSize: { xs: "36px", sm: "45px" } }} variant="titles2">OUR PARTNERS</Typography>
        </Box>
        <Grid container sx={{ marginTop: 10 }}>
          <Grid item xs={12} sm={3} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <PartnerTile image={KG} imageH={230} alt={"Koinos Group"} link={"https://koinos.group"} title={"Visit Koinos Group"} />
          </Grid>
          <Grid item xs={12} sm={3} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <PartnerTile image={RRS} imageH={160} alt={"Royal Reptile Studios"} />
          </Grid>
          <Grid item xs={12} sm={3} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <PartnerTile image={PK} imageH={160} alt={"Planet Koinos"} link={"https://planetkoinos.com/"} title={"Visit Planet Koinos"} />
          </Grid>
          <Grid item xs={12} sm={3} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <PartnerTile image={KoinCity} imageH={60} alt={"Koincity"} link={"https://koincity.com/"} title={"Visit Koincity"} />
          </Grid>
        </Grid>


        <Box sx={{ marginBottom: 10, display: "flex", justifyContent: "center", alignItems: "center" }}>  
          <OutlinedButton label="Become a partner" href="mailto:contact@koindx.com" /> 
        </Box>

      </Container>
    </Box>
  );
};


export default IndexPage;