import { Box, Container, Grid, Link, Typography } from '@mui/material';
import LogoWhite from '../../assets/img/logo_white.svg';

export const Footer = () => {
    return (
        <Box sx={{ width: "100%", padding: "10px", background: "#111111" }}>
            <Container>
                <Grid container spacing={2} sx={{ paddingTop: { xs: "20px", md: "40px" }, paddingBottom: "30px" }}>
                    <Grid item xs={12} md={5} sx={{ display: { xs: "flex", md: "block" }, justifyContent: { xs: "center", md: "left" } }}>
                        <Link style={{ display:"block", paddingTop: {xs:"0", md:"50px"} }} href="/">
                            <img src={LogoWhite} alt="KoinDX logo" style={{ maxHeight: "36px", float: "left" }} />
                        </Link>
                    </Grid>
                    <Grid item xs={12} md={7} sx={{ textAlign: { xs: "center", md: "left" } }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={4}>
                                <Box sx={{ marginTop: "20px", display: "flex", flexFlow: "column", textAlign: "center" }}>
                                    {/*<Link color="inherit" sx={{ textDecoration: "none", mx: "6px" }} href="https://app.koindx.com"><Typography component="span">App</Typography></Link>*/}
                                    <Link target={"_blank"} color="inherit" sx={{ textDecoration: "none", mx: "6px" }} href="https://koindx.notion.site/KoinDX-Media-kit-63aa8aab4bba4c6793dc0c3676f159ab"><Typography component="span">Media Kit</Typography></Link>
                                    <Link color="inherit" sx={{ textDecoration: "none", mx: "6px", my: { sx: "12px", sm: 0 } }} href="/privacy"><Typography component="span">Privacy Policy</Typography></Link>
                                    <Link color="inherit" sx={{ textDecoration: "none", mx: "6px" }} href="/terms-of-use"><Typography component="span">Terms of Use</Typography></Link>
                                    <Link color="inherit" sx={{ textDecoration: "none", mx: "6px" }} target="_blank" href="https://status.koindx.com"><Typography component="span">Status</Typography></Link>

                                </Box>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Box sx={{ marginTop: { xs: "20px", md: "20px" } }}>{
                                    <Box sx={{ marginTop: "20px", display: "flex", flexFlow: "column", textAlign: "center" }}>
                                        <Link target={"_blank"} color="inherit" sx={{ textDecoration: "none", mx: "6px" }} href="https://testnet.koindx.com"><Typography component="span">Testnet</Typography></Link>
                                        <Link target={"_blank"} color="inherit" sx={{ textDecoration: "none", mx: "6px" }} href="https://docs.koindx.com"><Typography component="span">Documentation</Typography></Link>
                                        <Link target={"_blank"} color="inherit" sx={{ textDecoration: "none", mx: "6px", display: "block" }} href="https://github.com/koindx" title="KoinDX GitHub"><Typography component="span">Github</Typography></Link>
                                    </Box>
                                }
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Box sx={{ marginTop: { xs: "20px", md: "20px" } }}>{
                                    <Box sx={{ marginTop: "20px", display: "flex", flexFlow: "column", textAlign: "center" }}>
                                        <Link target={"_blank"} color="inherit" sx={{ textDecoration: "none", mx: "6px", display: "block" }} href="https://medium.com/@koindx" title="KoinDX GitHub"><Typography component="span">Blog</Typography></Link>
                                        <Link target={"_blank"} color="inherit" sx={{ textDecoration: "none", mx: "6px", display: "block" }} href="https://x.com/koindx" title="KoinDX X (Formerly known as Twitter)"><Typography component="span">X</Typography></Link>
                                        <Link target={"_blank"} color="inherit" sx={{ textDecoration: "none", mx: "6px", display: "block" }} href="https://discord.gg/Wh49QvYB3X" title="KoinDX Discord"><Typography component="span">Discord</Typography></Link>
                                        <Link target={"_blank"} color="inherit" sx={{ textDecoration: "none", mx: "6px", display: "block" }} href="https://t.me/koindx" title="KoinDX GitHub"><Typography component="span">Telegram</Typography></Link>
                                    </Box>
                                }
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    )
}