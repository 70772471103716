
import { Outlet } from "react-router-dom";
import { Header } from './home/Header';
import { Footer } from './home/Footer';
import { useTheme, Box } from "@mui/material";

// images
import BackgroundWave from "./../assets/img/bg_wave_04.svg";


const backgroundStyles = {
    position: "absolute",
    width: "100%",
    height: "100vh",
    overflow: "hidden",
    margin: "0px",
    padding: "0px",
    backgroundSize: "auto",
    backgroundRepeat: "no-repeat",
    backgroundSize: "auto",
    backgroundOrigin: "content-box",
}

const ElipsesStyles = {
    position: "absolute",
    width: "100vw",
    height: {
        xs: "60vh",
        sm: "100vh"
    },
    overflowX: "hidden",
    margin: "0px",
    padding: "0px",
    backgroundSize: "auto",
    backgroundRepeat: "no-repeat",
    backgroundSize: "auto",
    backgroundOrigin: "content-box",
}

const containerSlepses = {
    position: "absolute",
    width: "100%",
    overflow: "hidden",
}


export const Layout = () => {
    const theme = useTheme();
    return (
        <Box sx={{ backgroundColor: theme.palette.background.default }}>

            {/* backgounds */}
            <Box sx={{ backgroundImage:`url(${BackgroundWave})`, ...backgroundStyles, opacity: "10%", zIndex: 0, display: { xs: "none", sm: "block" } }} />
            <Box sx={{ top: "-30vh", height: "115vh", ...containerSlepses, zIndex: 0, display: { xs: "block", sm: "none" } }}>
                <Box sx={{ top: "0vh", left: "-30vw", ...ElipsesStyles, transform: "matrix(0.94, -0.34, 0.34, 0.94, 0.00, -0.00)", background: { xs: "radial-gradient(ellipse, rgba(0,255,187,0.22) -50%, rgba(177,178,181,0) 75%)", sm: "radial-gradient(ellipse, rgba(0,255,187,0.22) -50%, rgba(177,178,181,0) 50%)" } }} />
            </Box>
            <Box sx={{ top: "30vh", height: "115vh", ...containerSlepses, zIndex: 0 }}>
                <Box sx={{ top: "0vh", right: "-30vw", ...ElipsesStyles, transform: "matrix(0.94, -0.34, 0.34, 0.94, 0.00, -0.00)", background: "radial-gradient(ellipse, rgba(0,255,187,0.22) -50%, rgba(177,178,181,0) 50%)" }} />
                <Box sx={{ top: "15vh", right: "-50vw", ...ElipsesStyles, transform: "matrix(0.94, -0.34, 0.34, 0.94, 0.00, -0.00)", background: "radial-gradient(ellipse, rgba(255,172,0,0.22) -50%, rgba(177,178,181,0) 50%)" }} />
            </Box>
            <Box sx={{ top: "130vh", height: "115vh", ...containerSlepses, zIndex: 0 }}>
                <Box sx={{ top: "0vh", left: "-30vw", ...ElipsesStyles, transform: "matrix(0.94, -0.34, 0.34, 0.94, 0.00, -0.00)", background: "radial-gradient(ellipse, rgba(0,255,187,0.22) -50%, rgba(177,178,181,0) 50%)" }} />
                <Box sx={{ top: "20vh", left: "-25vw", ...ElipsesStyles, transform: "matrix(0.94, -0.34, 0.34, 0.94, 0.00, -0.00)", background: "radial-gradient(ellipse, rgba(185,0,255,0.22) -50%, rgba(177,178,181,0) 50%)" }} />
            </Box>
            <Box sx={{ top: "200vh", height: "115vh", ...containerSlepses, zIndex: 0 }}>
                <Box sx={{ top: "0vh", right: "-30vw", ...ElipsesStyles, transform: "matrix(0.94, -0.34, 0.34, 0.94, 0.00, -0.00)", background: "radial-gradient(ellipse, rgba(0,255,187,0.22) -50%, rgba(177,178,181,0) 50%)" }} />
                <Box sx={{ top: "15vh", right: "-50vw", ...ElipsesStyles, transform: "matrix(0.94, -0.34, 0.34, 0.94, 0.00, -0.00)", background: "radial-gradient(ellipse, rgba(255,172,0,0.22) -50%, rgba(177,178,181,0) 50%)" }} />
            </Box>
            <Box sx={{ top: "320vh", height: "115vh", ...containerSlepses, zIndex: 0 }}>
                <Box sx={{ top: "0vh", left: "-30vw", ...ElipsesStyles, transform: "matrix(0.94, -0.34, 0.34, 0.94, 0.00, -0.00)", background: "radial-gradient(ellipse, rgba(0,255,187,0.22) -50%, rgba(177,178,181,0) 50%)" }} />
                <Box sx={{ top: "20vh", left: "-25vw", ...ElipsesStyles, transform: "matrix(0.94, -0.34, 0.34, 0.94, 0.00, -0.00)", background: "radial-gradient(ellipse, rgba(185,0,255,0.22) -50%, rgba(177,178,181,0) 50%)" }} />
            </Box>
            <Box sx={{ top: "400vh", height: "115vh", ...containerSlepses, zIndex: 0 }}>
                <Box sx={{ top: "0vh", right: "-30vw", ...ElipsesStyles, transform: "matrix(0.94, -0.34, 0.34, 0.94, 0.00, -0.00)", background: "radial-gradient(ellipse, rgba(0,255,187,0.22) -50%, rgba(177,178,181,0) 50%)" }} />
                <Box sx={{ top: "15vh", right: "-50vw", ...ElipsesStyles, transform: "matrix(0.94, -0.34, 0.34, 0.94, 0.00, -0.00)", background: "radial-gradient(ellipse, rgba(255,172,0,0.22) -50%, rgba(177,178,181,0) 50%)" }} />
            </Box>
            <Box sx={{ top: "480vh", height: "115vh", ...containerSlepses, zIndex: 0 }}>
                <Box sx={{ top: "0vh", left: "-30vw", ...ElipsesStyles, transform: "matrix(0.94, -0.34, 0.34, 0.94, 0.00, -0.00)", background: "radial-gradient(ellipse, rgba(0,255,187,0.22) -50%, rgba(177,178,181,0) 50%)" }} />
                <Box sx={{ top: "20vh", left: "-25vw", ...ElipsesStyles, transform: "matrix(0.94, -0.34, 0.34, 0.94, 0.00, -0.00)", background: "radial-gradient(ellipse, rgba(185,0,255,0.22) -50%, rgba(177,178,181,0) 50%)" }} />
            </Box>
            

            {/* only mobile */}
            <Box sx={{ top: "560vh", height: "115vh", ...containerSlepses, zIndex: 0, display: { xs: "block", sm: "none" } }}>
                <Box sx={{ top: "0vh", right: "-30vw", ...ElipsesStyles, transform: "matrix(0.94, -0.34, 0.34, 0.94, 0.00, -0.00)", background: "radial-gradient(ellipse, rgba(0,255,187,0.22) -50%, rgba(177,178,181,0) 50%)" }} />
                <Box sx={{ top: "15vh", right: "-50vw", ...ElipsesStyles, transform: "matrix(0.94, -0.34, 0.34, 0.94, 0.00, -0.00)", background: "radial-gradient(ellipse, rgba(255,172,0,0.22) -50%, rgba(177,178,181,0) 50%)" }} />
            </Box>
            <Box sx={{ top: "640vh", height: "115vh", ...containerSlepses, zIndex: 0, display: { xs: "block", sm: "none" } }}>
                <Box sx={{ top: "0vh", left: "-30vw", ...ElipsesStyles, transform: "matrix(0.94, -0.34, 0.34, 0.94, 0.00, -0.00)", background: "radial-gradient(ellipse, rgba(0,255,187,0.22) -50%, rgba(177,178,181,0) 50%)" }} />
                <Box sx={{ top: "20vh", left: "-25vw", ...ElipsesStyles, transform: "matrix(0.94, -0.34, 0.34, 0.94, 0.00, -0.00)", background: "radial-gradient(ellipse, rgba(185,0,255,0.22) -50%, rgba(177,178,181,0) 50%)" }} />
            </Box>
            <Box sx={{ top: "720vh", height: "115vh", ...containerSlepses, zIndex: 0, display: { xs: "block", sm: "none" } }}>
                <Box sx={{ top: "0vh", right: "-30vw", ...ElipsesStyles, transform: "matrix(0.94, -0.34, 0.34, 0.94, 0.00, -0.00)", background: "radial-gradient(ellipse, rgba(0,255,187,0.22) -50%, rgba(177,178,181,0) 50%)" }} />
                <Box sx={{ top: "15vh", right: "-50vw", ...ElipsesStyles, transform: "matrix(0.94, -0.34, 0.34, 0.94, 0.00, -0.00)", background: "radial-gradient(ellipse, rgba(255,172,0,0.22) -50%, rgba(177,178,181,0) 50%)" }} />
            </Box>
            <Box sx={{ top: "800vh", height: "115vh", ...containerSlepses, zIndex: 0, display: { xs: "block", sm: "none" } }}>
                <Box sx={{ top: "0vh", left: "-30vw", ...ElipsesStyles, transform: "matrix(0.94, -0.34, 0.34, 0.94, 0.00, -0.00)", background: "radial-gradient(ellipse, rgba(0,255,187,0.22) -50%, rgba(177,178,181,0) 50%)" }} />
                <Box sx={{ top: "20vh", left: "-25vw", ...ElipsesStyles, transform: "matrix(0.94, -0.34, 0.34, 0.94, 0.00, -0.00)", background: "radial-gradient(ellipse, rgba(185,0,255,0.22) -50%, rgba(177,178,181,0) 50%)" }} />
            </Box>
            


            {/* Page */}
            <Header sx={{ zIndex: 2 }} />
            <Outlet sx={{ zIndex: 2 }} />
            <Footer sx={{ zIndex: 2 }} />
        </Box>
    )
}