import { Container, Link, Button, Typography, Box } from '@mui/material';
import LogoWhite from '../../assets/img/logo_white.svg';

export const Header = () => {
    return (
        <Box style={{ minHeight: "120px", width: "100%" }}
        >
            <Container style={{ paddingTop: "40px" }}>
                <Box sx= {{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <Box>
                        <Link href="/">
                            <img src={LogoWhite} alt="KoinDX logo" style={{ maxHeight: "36px", float: "left" }} />
                        </Link>
                    </Box>
                    <Box>
                        <Button sx={{ display: { xs: "none", sm: "inline" } }}  size="small" href="https://docs.koindx.com">
                            <Typography sx={{ marginX: 1 }} variant="body2" component="span">DOCS</Typography>
                        </Button>
                        <Button sx={{ display: { xs: "none", sm: "inline" } }}  size="small" href="https://medium.com/@koindx">
                            <Typography sx={{ marginX: 1 }} variant="body2" component="span">BLOG</Typography>
                        </Button>
                        <Button sx={{ display: { xs: "none", sm: "inline" } }}  size="small" href="https://docs.koindx.com/faq/troubleshooting">
                            <Typography sx={{ marginX: 1 }} variant="body2" component="span">FAQ</Typography>
                        </Button>
                        <Button sx={{ display: { xs: "none", sm: "inline" } }}  size="small" href="https://docs.koindx.com/community">
                            <Typography sx={{ marginX: 1 }} variant="body2" component="span">COMMUNITY</Typography>
                        </Button>
                        <Button sx={{ marginX: 1 }} variant="contained" size="small" href="https://app.koindx.com">
                            <Typography variant="button" component="span">LAUNCH APP</Typography>
                        </Button>
                    </Box>
                </Box>
            </Container>
        </Box>
    )
}