import { get as _get } from "lodash"
import { Request as JSONRequest } from "../helpers/request";

class GlobalController extends JSONRequest {
  constructor() {
    super({ url: process.env.REACT_APP_BACKEND_API_URL });
  }

  async getBlockHeight() {
    return this.getPrepared("/v2/global")
  }

  async getData() {
    return this.getPrepared("/v2/tvl")
  }
}


export let global = new GlobalController();