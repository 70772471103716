import { Button, Typography } from '@mui/material';

export const OutlinedButton = ({ label, href, target }) => {
    return (
        <Button href={href} target={target} size="large" variant="outlined"  >
            <Typography variant="button" sx={{padding: "6px"}}>
                {label}
            </Typography>
        </Button>
    )
}
