import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  darkMode: false,
}

export const settingsSlice = createSlice({
  
  name: 'settings',
  initialState,
  reducers: {
    toggle: (state) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.darkMode = !state.darkMode
    },
    setDarkMode: (state,action) => {
      state.darkMode =  action.payload
    }
  },
})

// Action creators are generated for each case reducer function
export const { toggle, setDarkMode} = settingsSlice.actions

export default settingsSlice.reducer